<template>
	<div>
		<a-modal :title="getTitle" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div class="flex  mt10">
						<a-input class="woyaou-input " prefix="手机号" style="width: 230px;" placeholder="请输入手机号" v-model="form.mobile" :maxLength="20">
						</a-input>
						<a-input class="woyaou-input ml20" style="width: 230px;" prefix="股东姓名" placeholder="请输入股东姓名" v-model="form.name" :maxLength="20">
						</a-input>
					</div>
					<div class="mt20">有效期至</div>
					<div class="mt10">
					  <a-date-picker  valueFormat="YYYY-MM-DD" v-model="form.expire_time"/>
					</div>
					
					<div class="mt24">
						<div>选择股金</div>
						<div class="mt10">
							<div class="flex wrap" v-if="dividend_ratio_data.length>0">
								<template v-for="(item,index) in dividend_ratio_data">
									<div v-if="index==select_index" class="share_capital_item active">{{item.share_capital}}元</div>
									<div v-else class="share_capital_item clickAct" @click="chooseIt(index)">{{item.share_capital}}元</div>
								</template>
							</div>
							<div v-else class="ml10">未设置股金数据</div>
						</div>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			partner_id:{
				type:Number,
				default:0,
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				dividend_ratio_data:[],
				select_index:-1,
				form: {
					name:'',
					mobile:'',
					expire_time:'',
				}
			}
		},
		created() {
			this.loaddata();
		},
		computed:{
			getTitle(){
				if(this.partner_id==0){
					return '添加股东';
				}else{
					return '编辑股东';
				}
			}
		},
		methods: {
			
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showPartner',{
					partner_id:this.partner_id
				}).then(res=>{
					this.loading=false;
					this.dividend_ratio_data=res.dividend_ratio_data;
					if(res.detail){
						this.form=res.detail;
					}
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$emit("cancel");
				})
			},
			
			chooseIt(index){
				this.select_index=index;
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/savePartner',{
					partner_id:this.partner_id,
					name:this.form.name,
					mobile:this.form.mobile,
					expire_time:this.form.expire_time,
					index:this.select_index,
					type:1,
				}).then(res=>{
					this.$message.success('保存成功');
					this.$emit("ok");
					this.confirmLoading=false;
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
				
			},
			
		}
	}
</script>

<style>
	.share_capital_item{
		padding: 4px 16px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		font-size: 16px;
		font-weight: 500;
		color: #232529;
		margin-right: 10px;
	}
	
	.share_capital_item.active{
		padding: 4px 16px;
		background: #FFFFFF;
		border: 1px solid #4772FF;
		font-size: 16px;
		font-weight: 500;
		color: #4772FF;
		margin-right: 10px;
	}
	
</style>
