<template>
	<div>
		<a-modal title="延期" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定延期
				</a-button>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div class="mt20">有效期至</div>
					<div class="mt10">
					  <a-date-picker  valueFormat="YYYY-MM-DD" v-model="form.expire_time"/>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			partner_id:{
				type:Number,
				default:0,
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form: {
					expire_time:'',
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods: {
			
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showPartner',{
					partner_id:this.partner_id
				}).then(res=>{
					this.loading=false;
					if(res.detail){
						this.form=res.detail;
					}
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$emit("cancel");
				})
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/delayPartner',{
					partner_id:this.partner_id,
					expire_time:this.form.expire_time,
				}).then(res=>{
					this.$message.success('延期成功');
					this.$emit("ok");
					this.confirmLoading=false;
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
				
			},
			
		}
	}
</script>

<style>
	.share_capital_item{
		padding: 4px 16px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		font-size: 16px;
		font-weight: 500;
		color: #232529;
		margin-right: 10px;
	}
	
	.share_capital_item.active{
		padding: 4px 16px;
		background: #FFFFFF;
		border: 1px solid #4772FF;
		font-size: 16px;
		font-weight: 500;
		color: #4772FF;
		margin-right: 10px;
	}
	
</style>
