export const listMixin = {
    data(){
        return {
            pagination: {
            	current: 1,
            	pageSize: 10, //每页中显示10条数据
            	total: 0,
            },
            loading:false,
            datas:[],
            selectedRowKeys:[],
			search:{},
			pre_search:{},
            isPageInit:false
        }
    },
    created(){
		for(let key  in this.search){
		     this.pre_search[key]=this.search[key];
		}
		console.log(this.pre_search);
        this.isPageInit = true;
        this.getLists();
        this.isPageInit = false;

    },
    methods:{
        pageChange(e){
            this.pagination.current = parseInt(e);
            this.getLists();
        },
        searchAct(){
            this.pagination.current = 1;
            this.getLists();
        },
        cancelAct(){
            this.pagination.current = 1;
            this.searchCancel();
            this.getLists();
        },
        //列表选中时操作
        onSelectChange (selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys
        },
        searchCancel(){
            //改变搜索的表单的初始值
			for(let key  in this.pre_search){
			     this.search[key]=this.pre_search[key];
			}
        },
        getLists(){
            this.loading = true;

            setTimeout(()=>{
                this.loading = false;
            },2000);
        }
    }
}
